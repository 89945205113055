/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.wrapperClass{
    padding: 20px;
}



/* ------------------------ Table css -------------------*/
.tableHead-addButton{ 
    height: 30px;
    background: #fff;
    /* border: 1px solid aliceblue; */
    /* text-align: center; */
    width: 100px;
    border-radius: 3px;
    font-weight: 500;
    outline: none !important;
    color: black;
    line-height: 30px;
}
.table-top-header{
    background: #91adb4cc;
    color: #fff;
    margin-top: 20px;
    font-weight: 500;
    height: 36px;
    font-size: 14px;
    padding-left: 15px;
    border-radius: 4px 4px 0 0;
    padding-top: 3px;
}
.table-top-header button , .table-top-header input{
    line-height: 0;
    padding: 0px;
    margin: 0px;
}
.table-top-header input{
    border-radius: 4px;
    padding-left: 10px;
    height: 30px;
    border: 0;
}
.dt-table{
    width:100%;
    margin:0;
}
.dt-table .table-title{
    background: #0e4b78 !important;
    text-align: left !important;
    color:#fff;
    font-weight: 500;
}
.dt-table th {
    background-color: #C9D9EC;
    font-size: 13px;
    font-weight: 500;
    color: #000;
    height: 30px;
    padding: 5px !important;
    text-align: left;
    white-space: normal;
    min-width: 100px;
    line-height: 1.3;
}

.dt-table tr{
    padding: 5px;
    font-style: regular;
    border-bottom: 1px solid #dee2e6;
}
.dt-table td {
    text-align: left;
    height:30px;
    margin:0;
    padding: 5px !important;
    font-size: 12px;
    vertical-align: middle !important;
    background:#fff;
    white-space: normal;
}
/* .dt-table td, .dt-table tr{
    border-left: 1px solid #dee2e6;
} */
.dt-table td:hover {
    background: #eee;
    font-size: 12px;
}
.dt-table td span {
    width:100%;
}
.dt-table td a {
    border:1px transparent solid;
    width:100%;
    display:inline-block;
    margin:0;
    padding: 5px !important;
    outline:0;
   cursor: pointer;
}
.dt-table td a:hover {
    text-decoration:none;
    background-color:#eee;
    border-radius:4px;
    /* border-color:#ddd; */
}
.delete-icon{
    font-size: 20px;
    cursor: pointer;
    outline:0;
}
.btn-save{
    width : 140px;
    outline:none !important;
    cursor: pointer;
    /* background-color: #0e4b78; */
    background-color: rgb(84, 131, 133);
    color: #fff;
    font-weight: 500;
}
.btn-cancel{
    width : 140px;
    outline:none !important;
    cursor: pointer;
    color: #000;
    font-weight: 500;
}
.font-12{
    font-size: 12px;
}
.dialog-box .mat-dialog-container{
    padding: 0px;
}
.errorDiv{
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    width: 100%;    
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.no-data{
    bottom: 4px;
    left: 0;
    margin-top: 30px;
    opacity: 0.5;
    right: 0;
    text-align: center;
    font-size: 16px;
}
.close-icon{
    cursor: pointer;
}
